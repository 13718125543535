<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('agelessImg/banner1.jpg')"
      >
      </parallax>
      <div class="content-center" style="margin-top: 1em;">
        <div class="container">
          <h2 style="margin-bottom: 0.25em;">Ageless Beauty</h2>
          <div class="text-center">
              <span style="margin-right: 1em;">Skin Care</span>
              <span style="margin-right: 1em;">Beauty Therapy</span>
              <span>Massage</span>
          </div>
          <img style="margin-top: 2.5em; height: 20vh;" src="agelessImg/logo.png" />
        </div>
      </div>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <!--<h2 class="title">Who are we?</h2>-->
            <h5 class="description">
              With over 25 years of experience in beauty therapy, skin care and massage, 
              Ageless Beauty Skin Care Clinic delivers effective treatments that work.
            </h5>
          </div>`
        </div>
        <div class="separator separator-info"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('agelessImg/banner2.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-info">
                  "The specialised treatments we provide can have a profound impact 
                  on customers, and I love being a part of that."
                  <br />
                  <br />
                  -- Cynthia (Principal)
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('agelessImg/service1.jpg')"
              ></div>
            </div>
            <div class="col-md-5 description">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('agelessImg/banner.jpg')"
              ></div>
              <h3>
                All Your Needs
              </h3>
              <!--
              <p>
                Ageless Beauty Skin Care Clinic specialise in the treatment cellulite using 
                Acoustic Wave Therapy and Rejuvenating skin treatments using Environ and 
                Itraceuticals products. Our clinic also offers a full range of beauty treatments.
              </p>
              -->
              <p>
                Ageless Beauty Skin Care Clinic delivers effective treatments. From massage, facials,
                waxing, tinting, manicures and pedicures, to cellulite and skin-tightening treatments, 
                there is something to cater for your every want or need
              </p>
              <p>
                Ageless Beauty Skin Care Clinic is Perth’s principal Cellulite Clinic specialising in
                Acoustic Wave Therapy; a treatment against cellulite developed by STORZ Medical of
                Switzerland, which involves the use of 'Shock-Waves'.
              </p>
              <p>
                Ageless Beauty offers other specialised therapies, including:
                <ul>
                  <li>Medical-grade Mattioli MicroDermabrasion</li>
                  <li>'Iso-Lift' DermoElectroporation</li>
                  <li>Photo Rejuvenation and Environ Skincare</li>
                  <li>Intraceuticals O2 facial Infusions, and</li>
                  <li>A range of high quality therapeutic treatments.</li>
                </ul>
              </p>
              <p>
                Take time out to be indulged and 
                pampered and allow yourself to escape all earthly pressures of modern day living with 
                one of our spa packages. At Ageless Beauty, we are confident that you will enjoy your 
                visit and look forward to welcoming you. For more information please contact us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Meet our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="agelessImg/cynthia.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Cynthia Paull</h4>
                <p class="category text-info">Principal Beauty Therapist</p>
                <p class="description">
                  Cynthia has worked in the beauty therapy industry for over 25 years
                  and enjoys running a small, family owned business where you can
                  connect with long term customers.  Cynthia's work is of a very high
                  standard.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="agelessImg/chris.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Chris Paull</h4>
                <p class="category text-info">Business Manager</p>
                <p class="description">
                  With a background in technical services, teaching and project management,
                  Chris brings unique skills to Ageless Beauty.  You will often be greated
                  by Chris in reception or on the phone where he'll ensure all your needs 
                  are catered for.
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Getting in touch</h2>
        <p class="description">We'd love to hear from you.</p>
        <div class="row text-left">
          <div class="col-lg-6 col-12">
            <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020.270152088749!2d115.85871442435887!3d-31.871240829044613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32b02cae3266af%3A0xc9e7811a0ba95b04!2sAgeless%20Beauty%20Skin%20Care%20Clinic!5e0!3m2!1sen!2sau!4v1616069776230!5m2!1sen!2sau" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020.270152088749!2d115.85871442435887!3d-31.871240829044613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32b02cae3266af%3A0xc9e7811a0ba95b04!2sAgeless%20Beauty%20Skin%20Care%20Clinic!5e0!3m2!1sen!2sau!4v1616069776230!5m2!1sen!2sau" width="100%" height="500" min-height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            <p />
          </div>
          <div class="col-lg-6 col-md-10 col-12 ml-auto mr-auto description">
            <div class="row">
              <div class="col-3">
                <p>
                  Address<br />
                  <br />
                </p><p>
                  Phone
                </p><p>
                  Email<br />
                  <br />
                </p><p>
                  Opening hours
                </p>
              </div>
              <div class="col-9">
                <p>
                  Suite 2, 37 Yirrigan Drive<br />
                  Mirrabooka WA 6061<br />
                </p><p>
                  0438 908 550<br />
                </p><p>
                  skincare@agelessbeauty.com.au<br />
                  bookings@agelessbeauty.com.au
                </p>
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-danger">
                      <div class="row">
                        <div class="col-1">
                          <i class="fas fa-info-circle"></i>
                        </div>
                        <div class="col-11">
                          Please Note: Ageless Beauty will be temporarily closed for holidays until <b>11th January 2025</b>.  Our usual business hours are shown below.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <p>
                      Mon<br />
                      Tues<br />
                      Wed<br />
                      Thur<br />
                      Fri<br />
                      Sat<br />
                      Sun
                    </p>
                  </div>
                  <div class="col">
                    <p>
                      Closed<br />
                      11:00 am – 7:00 pm<br />
                      9:00 am – 5:30 pm<br />
                      11:00 am – 7:00 pm<br />
                      9:00 am – 5:30 pm<br />
                      9:00 am – 2:00 pm<br />
                      Closed
                    </p>
                  </div>
                </div>  
                <p>
                  Other hours by arrangement
                </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Send us a message</h2>
        <p class="description">We'd love to hear from you!</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <form
              name="web-contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              @submit.prevent="handleSubmit"
            >
              <fg-input
                class="input-lg"
                placeholder="Name..."
                v-model="form.name"
                addon-left-icon="now-ui-icons users_circle-08"
              >
              </fg-input>
              <fg-input
                class="input-lg"
                placeholder="Email..."
                v-model="form.email"
                addon-left-icon="now-ui-icons ui-1_email-85"
              >
              </fg-input>
              <div class="textarea-container">
                <textarea
                  class="form-control"
                  name="name"
                  rows="4"
                  cols="80"
                  v-model="form.message"
                  placeholder="Type a message..."
                ></textarea>
              </div>
              <div class="send-button">
                <n-button type="info" round block size="lg" @click="handleSubmit">Send Message</n-button>
              </div>
              {{messageText}}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Button, FormGroupInput } from '@/components';
import axios from 'axios'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      messageText: "",
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    resetForm() {
      this.$set(this.form, 'name', '');
      this.$set(this.form, 'email', '');
      this.$set(this.form, 'message', '');
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');
    },
    handleSubmit() {
      
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' },
      };

      axios.post(
        '/',
        this.encode({
          'form-name': 'web-contact',
          ...this.form,
        }),
        axiosConfig,
      ).then( (response) => {
        this.resetForm();
        this.messageText = "Message sent.  Thank you!";
      })
      .catch( (error) => {
        //console.log( "Error submitting form: " + error.message )
        this.messageText = "Message send failed!";
      })      
    }
  }
};
</script>
<style>
@media (orientation:landscape) {
    .hide-on-landscape {
        display: none;
    }
}

@media (orientation:portrait) {
    .hide-on-portrait {
        display: none;
    }
}
</style>
